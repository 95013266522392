import styles from './styles.module.scss';
import { PortableText } from '@portabletext/react';
import React from 'react';
import { getParsedDate, SanityPost } from './Blogg';
import { useNavigate } from 'react-router-dom';

export function BlogTile({ post }: { post: SanityPost }) {
  const navigate = useNavigate();

  return (
    <div
      className={styles.blogTile}
      onClick={() => navigate('/blogg/' + post.slug.current)}
    >
      <div className={styles.imageWrapper}>
        <img
          src={post.mainImage}
          alt={post.alt}
          width={400}
          height={300}
          className={styles.image}
        />
      </div>
      <div className={styles.content}>
        <p className={styles.updatedAt}>{getParsedDate(post._updatedAt)}</p>
        <h2 className={styles.title}>{post.title}</h2>
        <PortableText value={post.excerpt} />
      </div>
    </div>
  );
}
