import React from 'react';
import CustomSection from '../CustomSection/CustomSection';
import { VisuallyHidden } from '@entur/a11y';
import {
  Blockquote,
  Heading1,
  Heading2,
  LeadParagraph,
  Link as EnturLink,
  ListItem,
  Paragraph,
  UnorderedList
} from '@entur/typography';
import { Helmet } from 'react-helmet';
import { ReactComponent as Avinor } from '../../assets/logo/avinor.svg';
import { ReactComponent as Banenor } from '../../assets/logo/banenor.svg';
import { ReactComponent as Entur } from '../../assets/logo/entur.svg';
import { ReactComponent as Jdir } from '../../assets/logo/jdir.svg';
import { ReactComponent as Kystverket } from '../../assets/logo/kystverket.svg';
import { ReactComponent as Nyeveier } from '../../assets/logo/nyeveier.svg';
import { ReactComponent as SVV } from '../../assets/logo/svv.svg';
import styles from './styles.module.scss';
import Timeline from '../Timeline/Timeline';
import { Link } from 'react-router-dom';

function OmOssSide() {
  return (
    <>
      <Helmet>
        <title>Hvem er vi? | Samferdselsdata</title>
      </Helmet>
      <CustomSection>
        <Heading1>Hvem er vi?</Heading1>
        <LeadParagraph>
          Det tverrsektorielle datasamarbeidet jobber for økt deling og
          utnyttelse av data i samferdselssektoren, og gjennom dette realisere
          samarbeidets ambisjon om å:
        </LeadParagraph>
        <Blockquote className={styles.quote}>
          «Tilrettelegge for bedre og mer effektive tjenester og
          beslutningsgrunnlag for innbyggerne, transportvirksomheter og øvrige
          beslutningstagere i samfunnet»
        </Blockquote>
        <Paragraph>
          Optimalisert bruk og deling av data i samferdselssektoren kan gi store
          gevinster, både til transportvirksomhetene, de reisende og samfunnet
          for øvrig. Noen eksempler er:
        </Paragraph>
        <UnorderedList>
          <ListItem>
            Mer presise analyser som kan føre til bedre planlegging,
            trafikkstyring og investeringer.
          </ListItem>
          <ListItem>
            Smartere, mer sømløs og helhetlig opplevelse for de reisende.
          </ListItem>
          <ListItem>
            Bedre informasjon til reisende som gjør det enklere å ta
            miljøbevisste valg.
          </ListItem>
          <ListItem>
            Bedre utnyttelse av dagens infrastruktur og transporttilbud.
          </ListItem>
        </UnorderedList>
        <Paragraph>
          Samarbeidet er initiert og finansiert av Samferdselsdepartementet og
          består av Avinor, Bane NOR, Entur AS, Jernbanedirektoratet,
          Kystverket, Nye Veier AS og Statens vegvesen. Entur har ansvaret for å
          koordinere samarbeidet.
        </Paragraph>
        <div className={styles.logoer}>
          <a
            href={'https://avinor.no/'}
            target="_blank"
            rel="noreferrer"
            className={styles.logo}
          >
            <Avinor />
            <VisuallyHidden>Gå til Avinor</VisuallyHidden>
          </a>
          <a
            href={'https://www.kystverket.no/'}
            target="_blank"
            rel="noreferrer"
            className={styles.logo}
          >
            <Kystverket />
            <VisuallyHidden>Gå til Kystverket</VisuallyHidden>
          </a>
          <a
            href={'https://www.nyeveier.no/'}
            target="_blank"
            rel="noreferrer"
            className={styles.logo}
          >
            <Nyeveier />
            <VisuallyHidden>Gå til Nye Veier</VisuallyHidden>
          </a>
          <a
            href={'https://entur.no/'}
            target="_blank"
            rel="noreferrer"
            className={styles.logo}
          >
            <Entur />
            <VisuallyHidden>Gå til Entur</VisuallyHidden>
          </a>
          <a
            href={'https://www.vegvesen.no/'}
            target="_blank"
            rel="noreferrer"
            className={styles.logo}
          >
            <SVV />
            <VisuallyHidden>Gå til Statens Vegvesen</VisuallyHidden>
          </a>
          <a
            href={'https://www.jernbanedirektoratet.no/no/'}
            target="_blank"
            rel="noreferrer"
            className={styles.logo}
          >
            <Jdir />
            <VisuallyHidden>Gå til Jernbanedirektoratet</VisuallyHidden>
          </a>
          <a
            href={'https://www.banenor.no/'}
            target="_blank"
            rel="noreferrer"
            className={styles.logo}
          >
            <Banenor />
            <VisuallyHidden>Gå til Banenor</VisuallyHidden>
          </a>
        </div>
      </CustomSection>
      <CustomSection className={styles.timelineSection}>
        <Heading2>Fra forprosjekt til prosjekt og til varig samarbeid</Heading2>
        <Paragraph>
          Rammevilkårene for transportsektoren er krevende. Etter flere år med
          vekst i tildelingene blir det økonomiske handlingsrommet for sektoren
          mindre, samtidig som de ambisiøse transportpolitiske målene består.
          Kombinert med stadig nye krav fra EU og utfordrende klimamål, betyr
          det at transportvirksomhetene må jobbe smartere i årene som kommer.
          Flere av de statlige transportvirksomhetene startet derfor allerede i
          2020 en dialog som tydet på at et datasamarbeid kunne være en del av
          løsningen.
        </Paragraph>
        <Timeline />
        <Paragraph>
          Behovet virksomhetene så ble løftet til Samferdselsdepartementet som
          først bestilte et forprosjekt i 2020, og deretter et nytt i 2021. Det
          siste forprosjektet foreslo blant annet organisering av samarbeidet,
          økonomisk fordeling, mandat og en arbeidsplan på 1-3 år. I februar
          2022 ga Samferdselsdepartementet de syv transportvirksomhetene i
          oppdrag å gå i gang med prosjektet slik det er skissert i den siste
          forprosjektrapporten. Siden 2022 har samarbeidet blitt etablert og
          virksomhetene har bedre forståelse for hverandre. Samarbeidet brukes
          som en arena for å få til andre ting på tvers, som f.eks. initiativer
          til felles prosjekter og at sentrale forbedringsinitiativer har vært
          lagt til samarbeidet.
        </Paragraph>
      </CustomSection>
      <CustomSection>
        <Heading2>Fra prosjekt til kontinuerlig utviklende samarbeid</Heading2>
        <Paragraph>
          I 2022 ble prosjektorganisasjonen rigget opp, sentrale
          samarbeidsarenaer etablert og hypoteser fra forprosjektet testet.
          Dette innebar også konkret arbeid med tekniske løsninger for
          datadeling, samt arbeid med spesifikke caser for å identifisere hvor
          det lå mest potensial i å samarbeide om data. I tillegg ble det klart
          at samarbeidet bør jobbe med juridiske problemstillinger for å
          tilrettelegge for effektiv og sikker deling og bruk av data. I løpet
          av året ble det også stadig tydeligere hva samarbeidet skal være og
          ikke.
        </Paragraph>
        <Paragraph>
          Samarbeidet arbeider med å tilrettelegge for at virksomheter i og
          utenfor samarbeidet enkelt selv kan oppdage, få tilgang til og ta i
          bruk data i samferdselssektoren for å realisere egen verdi. En sentral
          del av arbeidet handler derfor om å spre en sterkere kultur for data i
          sektoren, noe som er en viktig forutsetning for en mer datadrevet
          sektor. På lengre sikt jobber vi for å bidra til en helhetlig
          datadelingsreise, og for å etablere de nødvendige strukturene som
          ivaretar et velfungerende og kontinuerlig utviklende datasamarbeid i
          samferdselssektoren.
        </Paragraph>
        <Paragraph>
          For mer informasjon om de tre arbeidsstrømmene i prosjektet,{' '}
          <EnturLink as={Link} to={'/ressurser'}>
            se "Hva gjør vi".
          </EnturLink>
        </Paragraph>
        <Paragraph>
          Det vi jobber for er et velfungerende datasamarbeid som kjennetegnes
          av:
          <UnorderedList>
            <ListItem>Kultur for data er etablert.</ListItem>
            <ListItem>
              Effektene av datasamarbeidet gir en smartere, smidigere og mer
              effektiv sektor.
            </ListItem>
            <ListItem>
              Ansatte i transportvirksomhetene har nødvendig kompetanse om data
              og bruk av data.
            </ListItem>
            <ListItem>
              Det er et fellesskap i sektoren hvor kompetanse og erfaringer
              flyter på tvers av virksomheter.
            </ListItem>
            <ListItem>
              Nødvendige fellesløsninger for datadeling er basert på brukerbehov
              og er selvbetjente.
            </ListItem>
            <ListItem>
              Data i sektoren er på felles standard, der det er hensiktsmessig,
              som gjør den enkel å bruke på tvers.
            </ListItem>
          </UnorderedList>
        </Paragraph>
      </CustomSection>
    </>
  );
}

export default OmOssSide;
