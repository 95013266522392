import React from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { getParsedDate } from '../Blogg/Blogg';
import { PortableText } from '@portabletext/react';
import { toPlainText } from '@portabletext/toolkit';
import { useSanityPosts } from '../Blogg/useSanityPosts';
import styles from './styles.module.scss';
import leaves from '../../assets/leavesIllustration.svg';
import { SANITY_DATASET, SANITY_PROJECT_ID } from '../../config/sanityClient';

export function BlogPost() {
  const { slug } = useParams();
  const sanityPosts = useSanityPosts();

  const sanityPost = sanityPosts.find((post) => post.slug.current === slug);

  if (!sanityPost || !slug) {
    return (
      <div className={styles.error}>
        <img alt={'Illustrasjon av blader'} src={leaves} />
        <p>
          Oj, denne siden finnes visst ikke. Sjekk om du skrev riktig, eller
          prøv igjen senere.
        </p>
      </div>
    );
  }

  const {
    title,
    author,
    excerpt,
    mainImage,
    alt: imageAlt,
    _updatedAt,
    body
  } = sanityPost;

  const plainExcerpt = toPlainText(excerpt);

  return (
    <div className={styles.blogPost}>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={plainExcerpt} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={plainExcerpt} />
        <meta property="og:image" content={mainImage} />
      </Helmet>
      <div>
        <header className={styles.header}>
          <h1 className={styles.title}>{title}</h1>
          <p className={styles.author}>Av {author}</p>
          <time className={styles.date} dateTime={_updatedAt}>
            {getParsedDate(_updatedAt)}
          </time>
        </header>
        <img src={mainImage} alt={imageAlt} className={styles.image} />

        <div className={styles.content}>
          <PortableText
            value={body}
            components={{
              types: {
                blockContentImage: (imageComponent) => {
                  const refParts = imageComponent.value.asset._ref.split('-');
                  const imageUuid = refParts[1];
                  const imageSize = refParts[2];
                  const imageExtension = refParts[3];
                  const imageUrl = `https://cdn.sanity.io/images/${SANITY_PROJECT_ID}/${SANITY_DATASET}/${imageUuid}-${imageSize}.${imageExtension}`;

                  return (
                    <div className={styles.textImageContainer}>
                      <img
                        className={styles.textImage}
                        src={imageUrl}
                        alt={imageComponent.value.alt_text}
                      />
                    </div>
                  );
                }
              }
            }}
          />
        </div>
      </div>
    </div>
  );
}
