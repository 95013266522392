import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './components/App/App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { PostHogProvider } from 'posthog-js/react';

const PUBLIC_POSTHOG_KEY = 'phc_OfajGfZ8sirvGkZwKXRW71JR58u9U97WWGtbpFj0Qp1';
const PUBLIC_POSTHOG_HOST = 'https://eu.i.posthog.com';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const options = {
  api_host: PUBLIC_POSTHOG_HOST
};

root.render(
  <BrowserRouter>
    <PostHogProvider apiKey={PUBLIC_POSTHOG_KEY} options={options}>
      <App />
    </PostHogProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
