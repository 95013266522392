import React from 'react';
import styles from './styles.module.scss';
import {
  type ArbitraryTypedObject,
  type PortableTextBlock
} from '@portabletext/types';
import { BlogTile } from './BlogTile';
import { Helmet } from 'react-helmet';
import CustomSection from '../CustomSection/CustomSection';
import { Heading1, LeadParagraph } from '@entur/typography';
import { useMediaQuery } from 'react-responsive';
import { ReactComponent as PersonReadingNewspaper } from '../../assets/decoration/PersonReadingNewspaper.svg';
import { useSanityPosts } from './useSanityPosts';

export interface SanityPost {
  alt: string;
  title: string;
  author: string;
  visible: boolean;
  _updatedAt: string;
  _id: string;
  slug: { current: string };
  excerpt: PortableTextBlock | ArbitraryTypedObject[] | PortableTextBlock[];
  body: PortableTextBlock | ArbitraryTypedObject[] | PortableTextBlock[];
  mainImage: string;
}

export function getParsedDate(date: string) {
  return new Date(date).toLocaleString('nb-NO', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });
}

export function Blogg() {
  const isMobileScreen = useMediaQuery({ query: '(max-width: 767px)' });
  const sanityPosts = useSanityPosts();
  const synligeSanityPoster = sanityPosts.filter((post) => post.visible);

  return (
    <>
      <Helmet>
        <title>Aktuelle saker | Samferdselsdata</title>
      </Helmet>
      <CustomSection className={styles.titleContainer}>
        <div>
          <Heading1>Velkommen til bloggen vår</Heading1>
          <LeadParagraph>
            På Samferdselsdata er vi opptatt av å gjøre transportdata
            tilgjengelig og nyttig for folk flest. Vi tror på verdien av å dele
            kunnskap, og vi ønsker å bidra til bedre løsninger for hverdagen –
            enten det gjelder kollektivtransport, infrastruktur eller smartere
            måter å bruke data på. I bloggen deler vi oppdateringer, erfaringer
            og små drypp fra arbeidet vårt. Her får du innblikk i hvordan
            transportdata kan gjøre en forskjell, enten du er en fagperson, en
            nysgjerrig innbygger, eller bare interessert i hvordan ting henger
            sammen.
          </LeadParagraph>
        </div>
        {!isMobileScreen && <PersonReadingNewspaper />}
      </CustomSection>
      <div className={styles.container}>
        <div className={styles.grid}>
          {synligeSanityPoster
            .sort(
              (a, b) =>
                new Date(b._updatedAt).getTime() -
                new Date(a._updatedAt).getTime()
            )
            .map((post) => (
              <BlogTile key={post._id} post={post} />
            ))}
        </div>
      </div>
    </>
  );
}
